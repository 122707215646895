const ruleStatusList = [
  {
    value: 'PAUSED',
    label: '规则暂停',
  },
  {
    value: 'ACTIVE',
    label: '规则开启',
  },
  {
    value: 'NOTIFY',
    label: '规则通知',
  },
  {
    value: 'CHANGE_BUDGET',
    label: '规则改预算',
  },
  {
    value: 'COPY',
    label: '规则复制组',
  },
  {
    value: 'CHANGE_BID',
    label: '规则调整竞价',
  },
];
export const autoRuleList = [
  {
    value: 'LAST_30_MINUTES&PAUSED',
    label: '近30分钟规则暂停',
  },
  {
    value: 'LAST_1_HOUR&PAUSED',
    label: '近60分钟规则暂停',
  },
  {
    value: 'TODAY&PAUSED',
    label: '当天规则暂停',
  },
  {
    value: 'LAST_30_MINUTES&NOTIFY',
    label: '近30分钟规则通知',
  },
  {
    value: 'LAST_1_HOUR&NOTIFY',
    label: '近60分钟规则通知',
  },
  {
    value: '',
    label: '更多',
    children: [
      {
        value: 'LAST_30_MINUTES',
        label: '近30分分钟',
        children: ruleStatusList,
      },
      {
        value: 'LAST_1_HOUR',
        label: '近1小时',
        children: ruleStatusList,
      },
      {
        value: 'LAST_2_HOURS',
        label: '近2小时',
        children: ruleStatusList,
      },
      {
        value: 'LAST_3_HOURS',
        label: '近3小时',
        children: ruleStatusList,
      },
      {
        value: 'LAST_4_HOURS',
        label: '近4小时',
        children: ruleStatusList,
      },
      {
        value: 'TODAY',
        label: '当天',
        children: ruleStatusList,
      },
    ],
  },
];
